<template>
    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <div class="d-flex flex-row align-items-center mb-3" style="gap: 0px">
            <BackButton />
            <h1 class="m-0 text-light">
                {{ t('pages.more.mySubscription.mySubscription') }}
            </h1>
        </div>
        <div v-if="!user.loading && user.accreditations">
            <div
                class="card shadow-sm p-3 d-flex flex-row align-items-center justify-content-center text-muted"
                style="height: 150px"
                v-if="user.accreditations.length == 0"
            >
                {{ t('pages.more.mySubscription.noSubscriptions') }}
            </div>
        </div>
        <div
            v-if="user.loading || !user.accreditations"
            style="height: 150px"
            class="card shadow-sm p-3 d-flex flex-row align-items-center justify-content-center"
        >
            <img
                src="@/assets/loading-icon.gif"
                style="width: 1.5rem; height: 1.5rem"
            />
        </div>
        <div
            v-else
            class="card mb-3 shadow-sm p-0"
            v-for="acc in user.accreditations"
            :key="acc.id"
        >
            <div class="p-3">
                <div v-if="user.accreditations.length != 0">
                    <span
                        v-if="acc.subscription_active"
                        class="badge text-bg-success"
                        >{{ t('pages.more.active') }}</span
                    >
                    <span
                        v-else-if="!acc.subscription_started"
                        class="badge text-bg-secondary"
                    >
                        {{ t('pages.more.notStarted') }}
                    </span>
                    <span v-else-if="acc.expired" class="badge text-bg-danger">
                        {{ acc.expiresInHumans }}
                    </span>
                    <div
                        class="d-flex align-items-center my-2"
                        style="gap: 1rem"
                    >
                        <span style="font-weight: 500">
                            <span v-if="acc.boat.brand">
                                {{ acc.boat.brand.name }}
                            </span>
                            {{ acc.boat.identity.name }}
                        </span>
                    </div>
                    <div class="d-flex flex-row align-items-center mb-1">
                        <div
                            style="width: 150px; font-weight: 500"
                            class="text-muted"
                        >
                            {{ t('pages.more.mySubscription.startDate') }}
                        </div>
                        {{ moment(acc.start).format('ddd Do MMMM YYYY') }}
                    </div>
                    <div class="d-flex flex-row align-items-center mb-1">
                        <div
                            style="width: 150px; font-weight: 500"
                            class="text-muted"
                        >
                            {{ t('pages.more.mySubscription.expiryDate') }}
                        </div>
                        {{ moment(acc.end).format('ddd Do MMMM YYYY') }}
                    </div>
                    <div class="d-flex flex-row align-items-center mb-1">
                        <div
                            style="width: 150px; font-weight: 500"
                            class="text-muted"
                        >
                            {{ t('pages.more.mySubscription.length') }}
                        </div>
                        {{
                            t('pages.more.mySubscription.days', {
                                days: acc.length,
                            })
                        }}
                    </div>
                    <div class="d-flex flex-row align-items-center mb-1">
                        <div
                            style="width: 150px; font-weight: 500"
                            class="text-muted"
                        >
                            <span v-if="acc.subscription_active">
                                {{ t('pages.more.mySubscription.expires') }}
                            </span>
                            <span v-else-if="acc.expired">
                                {{ t('pages.more.mySubscription.expired') }}
                            </span>
                            <span v-else>{{ t('pages.more.startsIn') }}</span>
                        </div>
                        <span v-if="acc.subscription_active">
                            <span v-if="acc.expiresIn.days != 0">
                                {{
                                    t('pages.more.mySubscription.days', {
                                        days: acc.expiresIn.days,
                                    })
                                }}
                                {{ ' ' }}
                            </span>
                            <span v-if="acc.expiresIn.hours != 0">
                                {{
                                    t('pages.more.mySubscription.hours', {
                                        hours: acc.expiresIn.hours,
                                    })
                                }}
                            </span>
                            <span
                                v-if="
                                    !acc.expiresIn.hours &&
                                    !acc.expiresIn.days &&
                                    acc.expiresIn.minutes != 0
                                "
                            >
                                {{ acc.expiresIn.minutes }}
                                {{ t('pages.more.mySubscription.minutes') }}
                            </span>
                            <span
                                v-if="
                                    !acc.expiresIn.hours &&
                                    !acc.expiresIn.days &&
                                    !acc.expiresIn.minutes &&
                                    acc.expiresIn.seconds != 0
                                "
                            >
                                {{ acc.expiresIn.seconds }}
                                {{ t('pages.more.mySubscription.seconds') }}
                            </span>
                        </span>

                        <span v-else>
                            <span v-if="acc.startsIn.days != 0">
                                {{
                                    t('pages.more.mySubscription.days', {
                                        days: acc.startsIn.days,
                                    })
                                }}
                                {{ ' ' }}
                            </span>
                            <span v-if="acc.startsIn.hours != 0">
                                {{
                                    t('pages.more.mySubscription.hours', {
                                        hours: acc.startsIn.hours,
                                    })
                                }}
                            </span>
                            <span
                                v-if="
                                    !acc.startsIn.hours &&
                                    !acc.startsIn.days &&
                                    acc.startsIn.minutes != 0
                                "
                            >
                                {{ acc.startsIn.minutes }}
                                {{ t('pages.more.mySubscription.minutes') }}
                            </span>
                            <span
                                v-if="
                                    !acc.startsIn.hours &&
                                    !acc.startsIn.days &&
                                    !acc.startsIn.minutes &&
                                    acc.startsIn.seconds != 0
                                "
                            >
                                {{ acc.startsIn.seconds }}
                                {{ t('pages.more.mySubscription.seconds') }}
                            </span>
                        </span>
                    </div>
                    <div
                        v-if="!acc.tokens_split_monthly"
                        class="d-flex flex-row align-items-center"
                        style="gap: 0rem"
                    >
                        <div
                            style="width: 150px; font-weight: 500"
                            class="text-muted"
                        >
                            {{ t('pages.more.mySubscription.tokensAvailable') }}
                        </div>
                        <div>
                            {{ acc.current_tokens }} / {{ acc.max_tokens }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="acc.tokens_split_monthly">
                <table class="table m-0">
                    <thead>
                        <tr style="font-size: 0.9rem">
                            <th
                                style="font-weight: 500"
                                class="text-muted text-uppercase ps-3"
                            >
                                {{ t('pages.more.mySubscription.month') }}
                            </th>
                            <th
                                style="font-weight: 500"
                                class="text-muted text-uppercase pe-3"
                            >
                                {{ t('pages.more.mySubscription.available') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="ma in acc.active_monthly_accreditations"
                            :key="'monthlyAccreditation' + ma.id"
                            :class="[
                                moment
                                    .utc(ma.month_year_timestamp)
                                    .endOf('day')
                                    .endOf('month')
                                    .tz('Europe/Paris', true)
                                    .isBefore(moment())
                                    ? 'd-none'
                                    : '',
                            ]"
                        >
                            <td class="ps-3">
                                {{
                                    moment(ma.month_year_timestamp).format(
                                        'MMMM YYYY'
                                    )
                                }}
                            </td>
                            <td class="pe-3">
                                {{ ma.current_tokens }} /
                                {{ ma.max_tokens }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- <div
                    style="width: 300px; font-weight: 500"
                    class="text-muted ps-3 pe-3 pb-2"
                >
                    {{ t('pages.more.mySubscription.tokensAvailable') }}
                </div>
                <div
                    v-for="ma in acc.active_monthly_accreditations"
                    class="border-top pb-2 ps-3 pt-2 pe-3 d-flex align-items-center justify-content-start"
                    :key="'monthlyAccreditation' + ma.id"
                    :class="[
                        isBeforeCurrentMonth(ma.month_year_timestamp)
                            ? 'text-muted'
                            : '',
                    ]"
                >
                    <div style="width: 160px">
                        
                    </div>
                    <div style="width: 120px">
                        
                    </div>
                </div> -->
                <!-- <div class="border-top pb-2 ps-3 pt-2 pe-3">
                    {{ acc.current_tokens }} / {{ acc.max_tokens }}
                </div> -->
            </div>
            <!-- <div
                class="mt-4"
                v-if="acc.spans_multiple_months && acc.reset_tokens_monthly"
            >
                <i class="fa fa-arrow-rotate-right me-2 text-muted"></i
                >{{ t('pages.more.mySubscription.spansMultipleMonths') }}
            </div> -->
            <!-- <div
                    style="width: 150px; font-weight: 500"
                    class="text-muted mb-3"
                >
                    {{ t('pages.more.mySubscription.yachtAccess') }}
                </div>
                <router-link :to="`/yachts/${acc.boat.identity.id}`">
                    <YachtItem width="100%" :yacht="acc.boat" />
                </router-link> -->
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';
import BackButton from '@/components/BackButton';
import { useUserStore } from '@/stores/user-store';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');
const moment = inject('moment');
const { t } = useI18n();

// function isBeforeCurrentMonth(date) {
//     const currentMonth = moment().startOf('day').startOf('month');
//     const toCheckMonth = moment.utc(date).tz('Europe/Paris', true);
//     return toCheckMonth.isBefore(currentMonth);
// }

// function isSameMonthAsCurrent(date) {
//     const currentMonth = moment().startOf('day').startOf('month');
//     const toCheckMonth = moment.utc(date).tz('Europe/Paris', true);
//     console.log(
//         currentMonth.toISOString(),
//         toCheckMonth.toISOString(),
//         toCheckMonth.isSame(currentMonth, 'month')
//     );
//     return toCheckMonth.isSame(currentMonth, 'month');
// }

const userStore = useUserStore();
const { user } = storeToRefs(userStore);
</script>
<style scoped>
table {
    --bs-table-bg: transparent !important;
}
</style>
